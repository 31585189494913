/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    ol: "ol",
    li: "li",
    ul: "ul",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-4-the-ideal-stocking-stuffer"
  }, "Day 4: The Ideal Stocking Stuffer"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2015/day/4"
  }, "https://adventofcode.com/2015/day/4")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2015/src/day_04.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "Santa needs help mining AdventCoin to use as stocking stuffers."), "\n", React.createElement(_components.p, null, "To do this, he needs to find ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/MD5"
  }, "MD5"), " hashes that start with at least 5 zeros in ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Hexadecimal"
  }, "hexadecimal"), "."), "\n", React.createElement(_components.p, null, "Today’s input is a secret key."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "abcdef\n")), "\n", React.createElement(_components.p, null, "2 parts form the input to the MD5 hash:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "The secret key"), "\n", React.createElement(_components.li, null, "A number (an integer without leading zeros)"), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your secret key is abcdef, the lowest number that produces a hash that starts with 5 zeros is 609043.\nThe MD5 hash of abcdef609043 start with 000001dbbfa…"), "\n", React.createElement(_components.li, null, "If your secret key is pqrstuv, the lowest number that produces a hash that starts with 5 zeros is 1048970.\nThe MD5 hash of pqrstuv1048970 starts with 000006136ef…"), "\n"), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The question asks for the lowest number that produces a hexadecimal MD5 hash that starts with 5 zeros."), "\n", React.createElement(_components.h3, {
    id: "option-1-a-for-loop"
  }, "Option 1: A for loop"), "\n", React.createElement(_components.p, null, "Some skeleton/pseudo-code to start with:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "for num in 0.. {\n    let input = key + num; // string concatenation\n    let hash = md5(input);\n    if hash starts with 5 zeros {\n        return num;\n    }\n}\n")), "\n", React.createElement(_components.p, null, "A bruteforce algorithm that calculates the hash 1 at a time and checks if it contains 5 zeros."), "\n", React.createElement(_components.p, null, "I’m not going to write the MD5 algorithm myself.\nTime for code someone else wrote, the ", React.createElement(_components.a, {
    href: "https://crates.io/crates/md-5"
  }, React.createElement(_components.code, null, "md-5"), " crate")), "\n", React.createElement(_components.p, null, "An MD5 hash is 16 bytes."), "\n", React.createElement(_components.p, null, "A byte is often represented as a pair of hexadecimal characters.\nSingular hex characters go from ", React.createElement(_components.code, null, "0"), " to ", React.createElement(_components.code, null, "F"), ", so those pairs go from ", React.createElement(_components.code, null, "00"), " to ", React.createElement(_components.code, null, "FF"), ".\nIn decimal that would be from ", React.createElement(_components.code, null, "0"), " to ", React.createElement(_components.code, null, "255"), "."), "\n", React.createElement(_components.p, null, "The result of the ", React.createElement(_components.code, null, "MD5"), " implementation I used returns those 16 bytes in a list."), "\n", React.createElement(_components.p, null, "There are 3 steps to get that list:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Create a ", React.createElement(_components.code, null, "hasher")), "\n", React.createElement(_components.li, null, "Put bytes into it"), "\n", React.createElement(_components.li, null, "Tell it to create a hash with that input"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use md5::{Digest, Md5};\n\nlet key = \"abcde\";\nlet num = 0;\n\nlet mut hasher = Md5::new();\nhasher.update(key);\nhasher.update(num.to_string().as_bytes());\nlet hash = hasher.finalize();\n")), "\n", React.createElement(_components.p, null, "That’s the part that creates a hash dealt with.\nNow the part that checks that hash (does it start with 5 zeros?)."), "\n", React.createElement(_components.p, null, "The first byte contains information for the first 2 hex characters.\nThe second byte contains information for the next 2 hex characters."), "\n", React.createElement(_components.p, null, "If a byte is zero, both hex characters are zero."), "\n", React.createElement(_components.p, null, "We check if first four hex characters are 0 by checking if their entire byte is 0.\n", React.createElement(_components.code, null, "hash[0] == 0 && hash[1] == 0")), "\n", React.createElement(_components.p, null, "The 5th hex character is the first character of a pair.\nWith some bit logic we turn the second character into a 0.\nThen we check if the resulting number is 0:\n", React.createElement(_components.code, null, "hash[2] & 0xF0 == 0")), "\n", React.createElement(_components.p, null, "That turns the entire check into:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "if (hash[0] == 0) && (hash[1] == 0) && ((hash[2] & 0xF0) == 0) {\n    // the first five characters of the hexadecimal MD5 are 0\n}\n")), "\n", React.createElement(_components.p, null, "This can be written a bit differently.\nWe ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Bitwise_operation#OR"
  }, "bitwise OR"), " every part first\nIf the result is 0, they were all 0."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "if hash[0] | hash[1] | (hash[2] & 0xF0) == 0 {\n    // the first five characters of the hexadecimal MD5 are 0\n}\n")), "\n", React.createElement(_components.p, null, "Putting it all together.\nI pulled the ", React.createElement(_components.code, null, "hasher"), " outside of the ", React.createElement(_components.code, null, "for"), " loop to reuse it.\nCreating a new one every iteration works too, reusing it avoids a bit of unnecessary work.\nThis means the call to ", React.createElement(_components.code, null, "finalize()"), " turns into a call to ", React.createElement(_components.code, null, "finalize_reset()"), ", clearing the ", React.createElement(_components.code, null, "hasher"), " so it’s ready for the next loop."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use md5::{Digest, Md5};\n\npub fn part_1(input: &str) -> u64 {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n\n    for num in 0.. {\n        hasher.update(key);\n        hasher.update(num.to_string().as_bytes());\n        let result = hasher.finalize_reset();\n        // an item in the result array is a byte represented by 2 hex characters: 00 to FF\n        // check if both hex characters at idx 0 are 0\n        // check if both hex characters at idx 1 are 0\n        // check if first hex character at idx 2 is 0\n        if (result[0] == 0) && (result[1] == 0) && ((result[2] & 0xf0) == 0) {\n            return num;\n        }\n    }\n\n    unreachable!()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-an-iterator-chain"
  }, "Option 2: An iterator chain"), "\n", React.createElement(_components.p, null, "This uses an open range again to loop from 0 until the maximum possible number."), "\n", React.createElement(_components.p, null, "The used number is indentical to the index in that sequence.\nWhen a hash with 5 zeros is found, we break the loop and return that index."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "pub fn part_1(input: &str) -> usize {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n\n    (0..)\n        .map(|num| {\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize_reset()\n        })\n        .position(|result| result[0] | result[1] | (result[2] & 0xF0) == 0)\n        .unwrap()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-3-parallellize-it"
  }, "Option 3: Parallellize it"), "\n", React.createElement(_components.p, null, "This problem requires a bunch of work that can be split up, a great scenario for ", React.createElement(_components.a, {
    href: "/blog/multithreading-rust"
  }, "multi threading"), "."), "\n", React.createElement(_components.p, null, "I decided to slightly tweak the solution with the iterator chain.\nThe ", React.createElement(_components.a, {
    href: "https://docs.rs/rayon/latest/rayon/index.html"
  }, React.createElement(_components.code, null, "rayon"), " crate"), " provides the tools to convert that code with minimal changes."), "\n", React.createElement(_components.p, null, "The open range explicitly has an end point now.\nThis lets us convert the iterator chain to a ", React.createElement(_components.a, {
    href: "https://docs.rs/rayon/latest/rayon/iter/index.html"
  }, "parallel iterator"), "."), "\n", React.createElement(_components.p, null, "The call to ", React.createElement(_components.code, null, "position"), " turned into ", React.createElement(_components.a, {
    href: "https://docs.rs/rayon/latest/rayon/iter/trait.IndexedParallelIterator.html#method.position_first"
  }, "a call to ", React.createElement(_components.code, null, "rayon"), "’s ", React.createElement(_components.code, null, "position_first")), "."), "\n", React.createElement(_components.p, null, "Notice the ", React.createElement(_components.code, null, "hasher"), " is inside the loop again."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use md5::{Digest, Md5};\nuse rayon::prelude::*;\n\npub fn part_1(input: &str) -> usize {\n    let key = input.as_bytes();\n\n    (0..usize::MAX)\n        .into_par_iter()\n        .map(|num| {\n            let mut hasher = Md5::new();\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize()\n        })\n        .position_first(|result| result[0] | result[1] | (result[2] & 0xF0) == 0)\n        .unwrap()\n}\n")), "\n", React.createElement(_components.p, null, "Curiously, on my machine (an older intel quadcore without hyperthreading),\nthe gains from multithreading do not outweight the added overhead.\nThe algorithm was marginally slower multithreaded than the same one ran sequentially."), "\n", React.createElement(_components.h3, {
    id: "bonus-avoiding-string-allocations"
  }, "Bonus: Avoiding string allocations"), "\n", React.createElement(_components.p, null, "In every loop, we turn the number into a ", React.createElement(_components.code, null, "String"), " only to immediately feed it to the ", React.createElement(_components.code, null, "hasher"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "hasher.update(num.to_string());\n")), "\n", React.createElement(_components.p, null, "Avoiding that ", React.createElement(_components.code, null, "String"), " creation can speed up the solution."), "\n", React.createElement(_components.p, null, "Using ", React.createElement(_components.a, {
    href: "https://docs.rs/numtoa/latest/numtoa/"
  }, "a crate like ", React.createElement(_components.code, null, "numtoa")), " can help with this."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "for"), " loop code then turns into:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    hl: "7,10"
  }, "use md5::{Digest, Md5};\nuse numtoa::NumToA;\n\npub fn part_1(input: &str) -> u64 {\n    let mut hasher = Md5::new();\n    let key = input.as_bytes();\n    let mut buffer = [0u8; 20];\n    for num in 0.. {\n        hasher.update(key);\n        hasher.update(num.numtoa(10, &mut buffer));\n        let result = hasher.finalize_reset();\n        if result[0] | result[1] | (result[2] & 0xf0) == 0 {\n            return num;\n        }\n    }\n\n    unreachable!()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-1"
  }, "Main code for part 1"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "use md5::{Digest, Md5};\n\npub fn part_1(input: &str) -> usize {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n    (0..)\n        .map(|num| {\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize_reset()\n        })\n        .position(|result| result[0] | result[1] | (result[2] & 0xF0) == 0)\n        .unwrap()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The question asks for the lowest number that produces a hexadecimal MD5 hash that starts with 6 zeros."), "\n", React.createElement(_components.p, null, "The code is almost identical to part 1."), "\n", React.createElement(_components.p, null, "That special logic to deal with the 5’th hex character can be deleted now."), "\n", React.createElement(_components.p, null, "The part where we check if a hash starts with zeroes changes to:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "result[0] | result[1] | result[2] == 0\n")), "\n", React.createElement(_components.p, null, "Ironically, this makes part2 simpler to code than part1."), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-2"
  }, "Main code for part 2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "use md5::{Digest, Md5};\n\npub fn part_2(input: &str) -> usize {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n\n    (0..)\n        .map(|num| {\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize_reset()\n        })\n        .position(|result| result[0] | result[1] | result[2] == 0)\n        .unwrap()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs",
    numberLines: true
  }, "use md5::{Digest, Md5};\n\npub fn part_1(input: &str) -> usize {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n\n    (0..)\n        .map(|num| {\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize_reset()\n        })\n        .position(|result| result[0] | result[1] | (result[2] & 0xF0) == 0)\n        .unwrap()\n}\n\npub fn part_2(input: &str) -> usize {\n    let key = input.as_bytes();\n    let mut hasher = Md5::new();\n\n    (0..)\n        .map(|num| {\n            hasher.update(key);\n            hasher.update(num.to_string().as_bytes());\n            hasher.finalize_reset()\n        })\n        .position(|result| result[0] | result[1] | result[2] == 0)\n        .unwrap()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
